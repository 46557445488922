import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function loadView(view) {
    return () => import(`../views/${view}.vue`);
}

const routes = [
    {
        path: "/",
        redirect: "/dashboard"
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {layout: 'main', auth: true},
        component: loadView('Dashboard')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {layout: 'main', auth: true},
        component: loadView('AdministratorForm')
    },
    {
        path: '/profile/activity',
        name: 'profile.activity',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('AdministratorActivities')
    },
    {
        path: '/administrator',
        name: 'administrator',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Administrators')
    },
    {
        path: '/administrator/create',
        name: 'administrator.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('AdministratorForm')
    },
    {
        path: '/administrator/:id/edit',
        name: 'administrator.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('AdministratorForm')
    },
    {
        path: '/administrator/:id/activity',
        name: 'administrator.activity',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('AdministratorActivities')
    },
    {
        path: '/partner',
        name: 'partner',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager', 'clinic']}},
        component: loadView('Partners')
    },
    {
        path: '/partner/create',
        name: 'partner.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PartnerForm')
    },
    {
        path: '/partner/:id/edit',
        name: 'partner.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager', 'clinic']}},
        component: loadView('PartnerForm')
    },
    {
        path: '/clinic',
        name: 'clinic',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('Clinics')
    },
    {
        path: '/clinic/create',
        name: 'clinic.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ClinicForm')
    },
    {
        path: '/clinic/:id/edit',
        name: 'clinic.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ClinicForm')
    },
    {
        path: '/emergency_call/create',
        name: 'emergency_call.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('EmergencyCallForm')
    },
    {
        path: '/emergency_call/:status',
        name: 'emergency_call.status',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager', 'clinic']}},
        component: loadView('EmergencyCalls')
    },

    {
        path: '/emergency_call/:id/edit',
        name: 'emergency_call.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('EmergencyCallForm')
    },
    {
        path: '/emergency_call/:id/:status/show',
        name: 'emergency_call.show',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager','clinic']}},
        component: loadView('EmergencyCallShow')
    },
    {
        path: '/patient',
        name: 'patient',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Patients')
    },
    {
        path: '/patient/create',
        name: 'patient.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PatientForm')
    },
    {
        path: '/patient/:id/edit',
        name: 'patient.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PatientForm')
    },
    {
        path: '/partner/:id/activity',
        name: 'partner.activity',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PartnerActivities')
    },
    {
        path: '/company',
        name: 'company',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Companies')
    },
    {
        path: '/company/create',
        name: 'company.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CompanyForm')
    },
    {
        path: '/company/:id/edit',
        name: 'company.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('CompanyForm')
    },
    {
        path: '/company_clinic',
        name: 'company_clinic',
        meta: {layout: 'main', auth: {roles: ['clinic']}},
        component: loadView('CompanyClinicForm')
    },
    {
        path: '/specialty',
        name: 'specialty',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Specialties')
    },
    {
        path: '/specialty/create',
        name: 'specialty.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('SpecialtyForm')
    },
    {
        path: '/specialty/:id/edit',
        name: 'specialty.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('SpecialtyForm')
    },
    {
        path: '/doctor_category',
        name: 'doctor_category',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('DoctorCategories')
    },
    {
        path: '/doctor_category/create',
        name: 'doctor_category.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('DoctorCategoryForm')
    },
    {
        path: '/doctor_category/:id/edit',
        name: 'doctor_category.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('DoctorCategoryForm')
    },
    {
        path: '/news',
        name: 'news',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('News')
    },
    {
        path: '/news/create',
        name: 'news.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('NewsForm')
    },
    {
        path: '/news/:id/edit',
        name: 'news.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('NewsForm')
    },
    {
        path: '/page',
        name: 'page',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Pages')
    },
    {
        path: '/page/create',
        name: 'page.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PageForm')
    },
    {
        path: '/page/:id/edit',
        name: 'page.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PageForm')
    },
    {
        path: '/faq',
        name: 'faq',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Faqs')
    },
    {
        path: '/faq/create',
        name: 'faq.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('FaqForm')
    },
    {
        path: '/faq/:id/edit',
        name: 'faq.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('FaqForm')
    },
    {
        path: '/sex',
        name: 'sex',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('Sexes')
    },
    {
        path: '/sex/create',
        name: 'sex.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('SexForm')
    },
    {
        path: '/sex/:id/edit',
        name: 'sex.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('SexForm')
    },
    {
        path: '/transport_type',
        name: 'transport_type',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('TransportTypes')
    },
    {
        path: '/transport_type/create',
        name: 'transport_type.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('TransportTypeForm')
    },
    {
        path: '/transport_type/:id/edit',
        name: 'transport_type.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('TransportTypeForm')
    },
    {
        path: '/symptom_category',
        name: 'symptom_category',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('SymptomCategories')
    },
    {
        path: '/symptom_category/create',
        name: 'symptom_category.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('SymptomCategoryForm')
    },
    {
        path: '/symptom_category/:id/edit',
        name: 'symptom_category.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('SymptomCategoryForm')
    },
    {
        path: '/symptom',
        name: 'symptom',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('Symptoms')
    },
    {
        path: '/symptom/create',
        name: 'symptom.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('SymptomForm')
    },
    {
        path: '/symptom/:id/edit',
        name: 'symptom.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('SymptomForm')
    },
    {
        path: '/crew',
        name: 'crew',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('Crews')
    },
    {
        path: '/crew/create',
        name: 'crew.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('CrewForm')
    },
    {
        path: '/crew/:id/edit',
        name: 'crew.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('CrewForm')
    },
    {
        path: '/type_crew_member',
        name: 'type_crew_member',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('TypeCrewMembers')
    },
    {
        path: '/type_crew_member/create',
        name: 'type_crew_member.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('TypeCrewMemberForm')
    },
    {
        path: '/type_crew_member/:id/edit',
        name: 'type_crew_member.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('TypeCrewMemberForm')
    },
    {
        path: '/crew_member',
        name: 'crew_member',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('CrewMembers')
    },
    {
        path: '/crew_member/create',
        name: 'crew_member.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('CrewMemberForm')
    },
    {
        path: '/crew_member/:id/edit',
        name: 'crew_member.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('CrewMemberForm')
    },
    {
        path: '/provided_treatment',
        name: 'provided_treatment',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ProvidedTreatments')
    },
    {
        path: '/provided_treatment/create',
        name: 'provided_treatment.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ProvidedTreatmentForm')
    },
    {
        path: '/provided_treatment/:id/edit',
        name: 'provided_treatment.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ProvidedTreatmentForm')
    },
    {
        path: '/medication',
        name: 'medication',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('Medications')
    },
    {
        path: '/medication/create',
        name: 'medication.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('MedicationForm')
    },
    {
        path: '/medication/:id/edit',
        name: 'medication.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('MedicationForm')
    },
    {
        path: '/prescription',
        name: 'prescription',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('Prescriptions')
    },
    {
        path: '/prescription/create',
        name: 'prescription.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('PrescriptionForm')
    },
    {
        path: '/prescription/:id/edit',
        name: 'prescription.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('PrescriptionForm')
    },
    {
        path: '/country',
        name: 'country',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('Countries')
    },
    {
        path: '/country/create',
        name: 'country.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('CountryForm')
    },
    {
        path: '/country/:id/edit',
        name: 'country.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('CountryForm')
    },
    {
        path: '/city',
        name: 'city',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('Cities')
    },
    {
        path: '/city/create',
        name: 'city.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('CityForm')
    },
    {
        path: '/city/:id/edit',
        name: 'city.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('CityForm')
     },

    {
        path: '/auth/login',
        name: 'login',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/Login')
    },
    {
        path: '/auth/register',
        name: 'register',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/Login')
    },
    {
        path: '/auth/forgot',
        name: 'forgot',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/Forgot')
    },
    {
        path: '/auth/forgot/check',
        name: 'reset_password',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/ResetPassword')
    },
    {
        path: '/404',
        name: 'error-404',
        meta: {layout: 'main', auth: true},
        component: loadView('Errors/NotFound')
    },
    {
        path: '/403',
        name: 'error-403',
        meta: {layout: 'single-page', auth: undefined},
        component: loadView('Errors/Forbidden')
    },
    {
        path: '/block',
        name: 'block',
        meta: {layout: 'single-page', auth: undefined},
        component: loadView('Errors/Blocked')
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        meta: {layout: 'single-page', auth: undefined},
        component: loadView('Errors/Maintenance')
    },
    {
        path: '*',
        redirect: '/404',
        meta: {layout: 'default', auth: undefined},
    },
];

Vue.router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        return window.scrollTo({top: 0, behavior: 'smooth'});
    },
    /*
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0
        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }
        return window.goTo(scrollTo)
    },
    */
    /*
    scrollBehavior() {
        return window.scrollTo({top: 0, behavior: 'smooth'});
    },
    */
    hashbang: false,
    linkActiveClass: 'active',
    transitionOnLoad: true,
    mode: 'history',
    base: __dirname,
    routes: routes
});

export default Vue.router;
