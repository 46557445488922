import { initializeApp } from "firebase/app"
import { getMessaging, isSupported } from "firebase/messaging"
//import { onBackgroundMessage } from 'firebase/messaging/sw'
//import { getAnalytics } from "firebase/analytics"

const firebaseConfig = {
    apiKey: "AIzaSyDQI_6bVqh2gIp-a6JBQ1yzPTDx66H3D7c",
    authDomain: "smart03-ee184.firebaseapp.com",
    projectId: "smart03-ee184",
    storageBucket: "smart03-ee184.firebasestorage.app",
    messagingSenderId: "342368729492",
    appId: "1:342368729492:web:776fa85e744faee894f781",
    measurementId: "G-YQ6ELEJEMR"
};

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)
//const analytics = getAnalytics(app)

/*
if (isSupported()) {
    // Register Worker
} else {
    console.log("Browser doesn't support FCM")
}
*/
